<route>
{
  "meta": {
    "permission": [
      "equipements.view_brandmodel"
    ]
  }
}
</route>

<template>
  <div>
    <dialbrand
      v-model="dialog"
      :to-edit="toEdit"
      :brandroot="selectedBrand"
      :list="true"
      @done="reload = true"
    />
    <i-table
      :title="selectedBrand !== null ? selectedBrand.name : $tc('brand', 2)"
      :headers="headers"
      app="equipements.brandmodel"
      api="equipment.brand"
      :reload.sync="reload"
      @click:create="open()"
      @click:edit="open($event)"
      v-bind="{ opt: opts }"
      :otherBtn="mode === 1 && brandModelBtn.edit"
    >
      <template v-slot:extraToolbar="{ on }">
        <span v-if="selectedBrand !== null"></span>
        <i-btn
          v-on="on"
          text
          color="text-dark"
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-arrow-left"
          :title="$t('back')"
          v-if="mode > 1"
          @click="changeMode({ mode: 1 })"
        >
        </i-btn>
      </template>
      <template v-slot:otherBtn="{ item }">
        <i-btn
          :title="$tc('model', 1)"
          outlined
          classes="mx-1"
          icon="fa-globe-americas"
          color="info"
          @click="changeMode({ mode: 2, item })"
        />
      </template>
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialbrand from '../../components/brand/modal.vue'
export default {
  components: {
    dialbrand
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text:
            this.brandModelBtn.delete || this.brandModelBtn.edit
              ? this.$t('action')
              : '',
          value:
            this.brandModelBtn.delete || this.brandModelBtn.edit
              ? 'actions'
              : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    brandModelBtn() {
      return {
        delete:
          this.getPermissions([`equipements.delete_brandmodel`]) ||
          this.isAdmin,
        edit:
          this.getPermissions([`equipements.change_brandmodel`]) || this.isAdmin
      }
    }
  },
  data() {
    return {
      loadingInternal: true,
      reload: false,
      dialog: false,
      toEdit: null,
      mode: 1,
      opts: undefined,
      selectedBrand: null
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    changeMode({ mode = 1, item = null }) {
      this.mode = mode
      this.selectedBrand = item
      this.getBrands()
    },
    async getBrands() {
      if (this.mode === 2) {
        this.opts = {
          brand: this.selectedBrand?.pk,
          page: 1
        }
      } else {
        this.opts = undefined
      }
    }
  }
}
</script>
